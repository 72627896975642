.home-main-banner{
    width: 100%;
    position: relative;
    height: 800px;
    margin-bottom: 300px;
    @media (min-width: 1200px){
        height: 650px;
        margin-bottom: 550px;
    }
    .bg-image{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @media (min-width: 1200px){
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 100px;
        @media (min-width: 1200px){
            padding-top: 200px;
        }
        .container{
            @media (min-width: 1200px){
                display: flex;
                justify-content: space-between;
            }

            .left-side{
                color: #fff;
                font-size: 16px;
                display: none;
                @media (min-width: 1200px){
                    display: block;
                    color: #000;
                    width: 45%;
                    padding-right: 5%;
                }
                .person-image{
                    margin-bottom: 20px;
                }
                .disclaimer{
                    margin-bottom: 30px;
                }
            }

            .right-side{
                color: #fff;
                @media (min-width: 1200px){
                    width: 45%;
                }
                .big-title{
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 42px;
                    line-height: 1.2;
                    margin-bottom: 20px;
                    @media (min-width: 1200px){
                        font-size: 54px;
                    }
                }
                .form-box{
                    margin-top: 30px;
                    background-color: #fff;
                    padding: 20px;
                    border-radius: 16px;
                    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.25);
                    @media (min-width: 1200px){
                        padding: 40px;
                    }
                    .box-title{
                        text-transform: uppercase;
                        text-align: center;
                        color: #000;
                        font-weight: 900;
                        font-size: 22px;
                        margin-bottom: 5px;
                    }
                    .box-subtitle{
                        color: #000;
                        font-weight: 500;
                        font-size: 16px;
                        margin-bottom: 30px;
                    }

                    .input-item{
                        width: 100%;
                        margin-bottom: 20px;
                        font-size: 14px;
                        input{
                            background-color: #ECEBEB;
                            border: 0;
                            border-radius: 16px;
                            width: 100%;
                            padding: 20px;
                            outline: none;
                        }

                        textarea{
                            width: 100%;
                            background-color: #ECEBEB;
                            border-radius: 16px;
                            height: 150px;
                            resize: none;
                            padding: 20px;
                            border: 0;

                        }
                    }

                    .submit{
                        background-color: #073A5F;
                        color: #fff;
                        text-align: center;
                        display: block;
                        padding: 10px;
                        border-radius: 4px;
                        transition: 0.4s;
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}