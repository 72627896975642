.course-detail-top-section{
    padding-top: 120px;
    @media (min-width: 1200px){
        width: 1000px;
        margin: auto;
    }
    .container{
        .breadcrumbs{
            margin-bottom: 20px;
            font-size: 14px;
            .breadcrumb-item{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .course-bar{
            background-color: #e4e4e4;
            border-bottom: 8px solid #0E87CB;
            padding: 20px;
            margin-bottom: 60px;
            .title{
                font-weight: 300;
                text-transform: uppercase;
                font-size: 32px;
                line-height: 1;
            }
            .subtitle{
                font-size: 32px;
                text-transform: uppercase;
                font-weight: 900;
            }
        }

        .flex-container{
            margin-bottom: 50px;
            @media (min-width: 1200px){
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }

            .left{
                margin-top: 40px;
                @media (min-width: 1200px){
                    width: 30%;
                    margin-top: 0;
                }
                .sign-up{
                    margin-top: 30px;
                    background-color: #073A5F;
                    color: #fff;
                    text-align: center;
                    display: block;
                    padding: 20px;
                    transition: 0.4s;
                    border-radius: 10px;
                    text-transform: uppercase;
                    font-size: 12px;
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }

            .right{
                width: 100%;
                @media (min-width: 1200px){
                    width: 50%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
}