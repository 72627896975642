.instructors-area  {
    margin-bottom: 50px;
    font-family: Acrom !important;
    position: relative;
    .side-by-side {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50px;
        left: 0;
        @include media(get-bp(desktop)) {
            display: grid;
            grid-template-columns: 276px calc(100% - 306px);
            grid-column-gap: 30px;
            margin-bottom: 100px;
        }
        &.is-active {
            visibility: visible;
            opacity: 1;
            position: relative;
            transition: 1s;
            top: 0;
        }
    }

    .title {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 22px;
        margin-bottom: 30px;

    }

    .left {
       width: max-content;
       max-width: 100%;
       margin: auto;
       height: 100%;

       img {
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
       }
    }

    .right {
        .desc p {
            font-weight: normal;
            font-size: 14px;
            line-height: 155.5%;
            color: #979797;
        }
    }

    .about {
        display: flex;
        align-items: center;
        width: max-content;
        margin: auto;
        margin-bottom: 30px;

        @include media(get-bp(desktop)) {
            margin: unset;
            margin-bottom: 30px;
        }
        img {
            margin-right: 10px;
        }

        h3 {
            font-weight: bold;
            font-size: 21px;
            line-height: 150.5%;
            margin: 0;
        }

        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 155.5%;
        }
    }

    .instructors-list {
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, calc(100% / 2 - 10px));
        justify-content: space-between;
        grid-column-gap: 10px;
        grid-row-gap: 40px;
        margin-bottom: 100px;

        @include media(get-bp(tablet-portrait)) {
            grid-template-columns: repeat(3, calc(100% / 3 - 20px));
        }

        @include media(get-bp(desktop)) {
            grid-template-columns: repeat(6, calc(100% / 6 - 20px));
        }

        li {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 40px;
            height: max-content;
            cursor: pointer;
            img {
                min-width: 105px;
                height: 105px;
                border-radius: 100%;
                object-fit: cover;
                transition: 0.5s;
                border: 3px solid transparent;
            }

            .left {
                min-height: 130px;
            }

            &.is-active {
                img {
                    border: 3px solid #9e01001f;
                    min-width: 120px;
                    height: 120px;
                }
            }

            h4 {
                font-weight: 500;
                font-size: 16px;
                line-height: 150.5%;
                display: flex;
                align-items: center;
                text-align: left;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                margin: 0;
                color: rgba(7, 58, 95, 0.96);
                width: 100%;

                // @include media(get-bp(tablet-portrait)) {
                //     font-size: 16px;
                // }
            }

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 155.5%;
                margin: 0;

                // @include media(get-bp(tablet-portrait)) {
                //     font-size: 16px;
                // }
            }
        }
    }
}