.other-cases{
    margin-top: 60px;
    margin-bottom: 80px;
    @media (min-width: 1200px){
        margin-top: 120px;
    }
    .container{
        @media (min-width: 1200px){
            width: 800px;
            margin: auto;
        }
        .title{
            text-transform: uppercase;
            font-weight: 900;
            font-size: 32px;
            text-align: center;
            margin-bottom: 30px;
        }

        .other-cases-listing{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .case-item{
                width: 80px;
                margin-bottom: 10px;
                height: 100px;
                margin-right: 20px;
                @media (max-width: 1100px){
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
                @media (min-width: 1200px){
                    width: 190px;
                    margin-right: 20px;
                    height: 190px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}