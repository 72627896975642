.page-404{
    padding-top: 150px;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        height: 70vh;
        overflow: hidden;
        padding-top: 50px;
        
    }
    .container{
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        .text{
            position: relative;
            z-index: 2;
            .title{
                color: #e52629;
                font-size: 32px;
                margin-bottom: 20px;
            }
            .subtitle{
                font-size: 16px;
                margin-bottom: 30px;
                @media (min-width: 1200px){
                    width: 430px;
                    margin: auto;
                    margin-bottom: 30px;
                }
            }

            .main-button{
                background-color: #e52629;
                padding: 10px 20px;
                color: #fff;
                display: inline-flex;
                transition: 0.4s;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
    .bg-number{
        position: absolute;
        top: -60px;
        left: 0;
        text-align: center;
        width: 100%;
        z-index: 1;
        color: #f0f0f0;
        font-size: 12rem;
        @media (min-width: 1200px){
            font-size: 62rem;
            line-height: 1;
        }
    }
}