.course-content{
    .text-box{
        margin-bottom: 40px;
        .title{
            text-transform: uppercase;
            font-weight: 900;
            font-size: 22px;
            margin-bottom: 15px;
        }
        .text-content{
            font-size: 14px;
            @media (min-width: 1200px){
                font-size: 18px;
            }
        }
    }
}