.page-contact{
    padding-top: 100px;
    @media (min-width: 1200px){
        padding-top: 150px;
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }
    }
    .left{
        margin-bottom: 60px;
        @media (min-width: 1200px){
            width: 50%;
            margin-bottom: 0;
        }
    }

    .right{
        @media (min-width: 1200px){
            padding-left: 100px;
        }
    }
    .title{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 28px;
    }
    .subtitle{
        margin-bottom: 40px;
    }

    .input-row{
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
        }

        .input-item{
            @media (min-width: 1200px){
                width: 48%;
            }
        }
    }

    .input-item{
        margin-bottom: 20px;
        input, textarea{
            background-color: #ECEBEB;
            width: 100%;
            padding: 20px;
            border: 0;
            border-radius: 8px;
            font-size: 14px;
            outline: none;
            user-select: none;
        }

        textarea{
            height: 150px;
            resize: none;
            outline: none;
        }
    }

    .submit{
        background-color: #073A5F;
        text-align: center;
        padding: 10px;
        display: block;
        color: #fff;
        border-radius: 8px;
        font-size: 14px;
        text-transform: uppercase;
        transition: 0.4s;
        &:hover{
            opacity: 0.8;
        }
        @media (min-width: 1200px){
            width: 270px;
        }
    }

    .address{
        margin-bottom: 30px;
    }

    .contact-item{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .icon{
            background-color: #50616F;
            color: #fff;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            border-radius: 50%;
        }
        .text{
            width: 150px;
            font-size: 12px;
            font-weight: 500;
            color: #000;
            @media (min-width: 360px){
                font-size: 14px;
            }
        }
    }

    .map-container{
        width: 100%;
        height: 400px;
        margin-top: 80px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}