.search-results{
    padding-top: 100px;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        padding-top: 130px;
    }
    .section-title{
        color: #e52629;
    }

    .columns-listing{
        .column-item{
            margin-bottom: 50px;
            display: block;
            @media (min-width: 1200px){
                display: flex;
                margin-bottom: 10px;
            }
            .photo{
                width: 100%;
                height: 250px;
                margin-bottom: 20px;
                @media (min-width: 1200px){
                    margin-right: 20px;
                    width: 320px;
                    height: 200px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .text-container{
                @media (min-width: 1200px){
                    flex: 1;
                }
                .top-row{
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 900;
                    margin-bottom: 10px;
                    @media (min-width: 1200px){
                       font-size: 14px;
                    }
                    .category{
                        color: #0088cc;
                    }
                    .date{
                        color: #a9a9a8;
                    }
                }

                .column-title{
                    font-weight: 900;
                    margin-bottom: 10px;
                    @media (min-width: 1200px){
                        font-size: 18px;    
                    }
                }
                .column-subtitle{
                    font-size: 14px;
                }
            }
        }
    }
}