.blue-banner{
    margin-bottom: 60px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
            &.reverse{
                flex-direction: row-reverse;
            }
        }
    }
    .text-box{
        background-color: #062740;
        color: #fff;
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;
        &.left{
            text-align: left;
        }
        @media (min-width: 1200px){
            width: 50%;
            padding: 70px 100px;
        }
        .title{
            text-transform: uppercase;
            font-weight: 900;
            font-size: 36px;
            margin-bottom: 20px;
        }
        .main-text{
            font-size: 14px;
            font-weight: 300;
        }
    }

    .photo{
        @media (min-width: 1200px){
            width: 50%;
        }
    }
}