.strategy-container{
    padding-top: 50px;
    padding-bottom: 50px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }

        .left{
            @media (min-width: 1200px){
                width: 50%;
                &.full{
                    width: 100%;
                }
            }
            .title{
                font-weight: 900;
                text-transform: uppercase;
                font-size: 22px;
                margin-bottom: 10px;
            }
        }

        .right{
            @media (min-width: 1200px){
                padding-left: 50px;
                width: 50%;
            }
        }
    }
}