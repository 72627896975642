
.mt-most-read .content-most ul {
  padding: unset;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  padding-top: 25px;
}
.mt-most-read .content-most ul.is-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.3s;
  position: relative;
}
