.declaration{
    background-color: #e4e4e4;
    padding-top: 50px;
    padding-bottom: 50px;
    .container{
        text-align: center;
        .icon{
            margin-bottom: 20px;
        }
        .title{
            text-transform: uppercase;
            font-size: 20px;
            margin-bottom: 30px;
            font-weight: 900;
            margin-bottom: 10px;
            @media (min-width: 1200px){
                width: 500px;
                margin: auto;
                margin-bottom: 10px;
            }
        }

        .subtitle{
            font-size: 12px;
            font-style: italic;
            @media (min-width: 1200px){
                width: 350px;
                margin: auto;
            }
        }

        .line{
            width: 60px;
            height: 3px;
            background-color: #073A5F;
            margin: auto;
            margin-top: 20px;
        }
    }
}