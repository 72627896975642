.other-news{
    margin-bottom: 70px;
    .container{
        .title{
            text-transform: uppercase;
            font-size: 24px;
            margin-bottom: 50px;
            font-weight: 900;
        }
        .news-listing{
            @media (min-width: 1200px){
                display: flex;
                
            }

            .news-item{
                margin-bottom: 40px;
                position: relative;
                display: block;
                &:hover{
                    .news-title{
                        text-decoration: underline;
                    }
                }
                @media (min-width: 1200px){
                    width: 30%;
                    padding-right: 40px;
                    margin-right: 40px;
                    &:last-child{
                        .line{
                            display: none;
                        }
                    }
                }
                .news-title{
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    font-size: 20px;
                }
                .news-description{
                    font-size: 14px;
                }

                .line{
                    @media (min-width: 1200px){
                        width: 2px;
                        height: 80px;
                        background-color: #aaa;
                        position: absolute;
                        right: 0;
                        top: 70px;
                    }
                }
            }
        }
    }
}