.about-section{
    margin-bottom: 90px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            align-items: center;
        }
        .left{
            margin-bottom: 20px;
            @media (min-width: 1200px){
                width: 50%;
                padding-left: 10%;
                margin-bottom: 0;
            }

            .title{
                font-weight: 900;
                text-transform: uppercase;
                font-size: 36px;
                margin-bottom: 15px;
            }
            p{
                font-size: 16px;
            }
            .disclaimer{
                color: #073A5F;
            }
        }

        .right{
            @media (min-width: 1200px){
                width: 50%;
                padding-left: 3%;
            }
            img{
                width: 100%;
            }
        }
    }
}