.request-section{
    margin-top: 50px;
    margin-bottom: 50px;
    .blue-row{
        background-color: #062740;
        color: #fff;
        padding-top: 30px;
        padding-bottom: 30px;
        text-transform: uppercase;
        font-size: 24px;
        text-align: center;
        @media (min-width: 1200px){
            padding-top: 70px;
            padding-bottom: 70px;
            font-size: 36px;
        }
    }
}