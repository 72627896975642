//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z(menu);
}

.main-header {
    color: get-color(dark);
    transition: 0.4s;
    h1 {
        margin: 0;
    }
    &.active{
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
        background-color: #fff;
        .desktop-menu{
            color: #000;
            .main-menu{
                .menu-item{
                    color: #000;
                }
            }

            .search-container{
                .icon{
                    color: #000;
                }

                input{
                    color: #000;
                    border-color: #000;
                    &::placeholder{
                        color: #000;
                    }
                }
            }

            .main-header__logo-container{
                filter: brightness(1) invert(0);
            }
        }
        .burger-icon-container{
            &.is-active{
                .burger-icon{
                    background-color: #fff;
                }
            }
        }
        .burger-icon {
            background-color: #000;
            &::before {
                background-color: #000;
            }
    
            &::after {
                background-color: #000;
            }
        }

        .mobile-search{
            color: #000;
        }

        .mobile-logo{
            filter: brightness(1) invert(0);
        }
    }


    &.always-active{
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
        background-color: #fff;
        .desktop-menu{
            color: #000;
            .main-menu{
                .menu-item{
                    color: #000;
                }
            }

            .search-container{
                .icon{
                    color: #000;
                }

                input{
                    color: #000;
                    border-color: #000;
                    &::placeholder{
                        color: #000;
                    }
                }
            }

            .main-header__logo-container{
                filter: brightness(1) invert(0);
            }
        }
        .burger-icon-container{
            &.is-active{
                .burger-icon{
                    background-color: #fff;
                }
            }
        }
        .burger-icon {
            background-color: #000;
            &::before {
                background-color: #000;
            }
    
            &::after {
                background-color: #000;
            }
        }

        .mobile-search{
            color: #000;
        }

        .mobile-logo{
            filter: brightness(1) invert(0);
        }
    }
}

//menu desktop
.desktop-menu {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-header__logo-container {
        filter: brightness(0) invert(1);
        width: 100px;
        height: 50px;
        @media (min-width: 1200px) {
            width: 120px;
            padding-top: 20px;
            padding-bottom: 10px;
            height: unset;
        }
    }

    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .search-container{
        text-align: right;
        .border-container{
            display: inline-flex;
            padding: 5px 8px;
            border-radius: 20px;
        }
        .icon{
            cursor: pointer;
            color: #fff;
        }
        input{
            border: 0;
            background-color: transparent;
            color: #fff;
            font-size: 14px;
            outline: none;
            transition: 0.4s;
            width: 0px;
            &.active{
                border-bottom: 1px solid #fff;
                width: 150px;
                margin-right: 10px;
            }
            &::placeholder{
                color: #fff;
            }
        }
    }


}

//menu mobile
.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    @media (min-width: 1200px) {
        display: none;
    }

    .mobile-logo {
        width: 80%;
        margin: 0;
        display: flex;
        justify-content: center;
        filter: brightness(0) invert(1);
        img{
            width: 100px;
            margin: auto;
            margin-left: -10px;
        }
    }

    .separator{
        width: 10%;
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        background-color: #fff;
        &::before {
            background-color: #fff;
        }

        &::after {
            background-color: #fff;
        }
    }

    .mobile-search{
        text-align: right;
        font-size: 22px;
        color: #fff;
        padding-top: 2px;
    }
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
}
