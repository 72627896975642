.courses-listing{
    .container{
        @media (min-width: 1200px){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .course-item{
            margin-bottom: 80px;
            @media (min-width: 1200px){
                width: 35%;
                margin-bottom: 130px;
            }
            .photo-container{
                width: 100%;
                height: 250px;
                position: relative;
                margin-bottom: 20px;
                overflow: hidden;
                .flag{
                    position: absolute;
                    top: -10px;
                    left: 20px;
                    z-index: 2;
                    font-size: 32px;
                    color: #D3A400;
                    @media (min-width: 1200px){
                        font-size: 42px;
                    }
                }
                .photo{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #000;
                    img{
                        width: 100%;
                        height: 100%;
                        opacity: 0.6;
                        object-fit: cover;
                    }
                }

                .content-container{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding: 20px;
                    color: #fff;
                    .title{
                        font-weight: 300;
                        text-transform: uppercase;
                        font-size: 20px;
                        @media (min-width: 1200px){
                            font-size: 24px;
                        }
                    }
                    .subtitle{
                        font-weight: 900;
                        text-transform: uppercase;
                        font-size: 20px;
                        @media (min-width: 1200px){
                            font-size: 24px;
                        }
                    }
                }
            }

            .course-description{
                font-size: 16px;
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .know-more{
                background-color: #C20E1A;
                color: #fff;
                padding: 10px 20px;
                border-radius: 8px;
                transition: 0.4s;
                &:hover{
                    opacity: 0.8;
                }
                i{
                    margin-left: 20px;
                }
            }
        }
    }

    
}

.pagination-container{
    margin-bottom: 50px;
    @media (min-width: 1200px){
        width: 600px;
        margin: auto;
        margin-bottom: 100px;
    }
    .container{
        display: flex;
        justify-content: space-between;
    }
    .move-button, .page-item{
        transition: 0.4s;
        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
    }

    .page-item{
        &.selected{
            font-weight: 900;
        }
    }
}