.home-services{
    margin-top: 50px;
    margin-bottom: 50px;
    .big-title{
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 30px;
        @media (min-width: 1200px){
            font-size: 32px;
            width: 500px;
            line-height: 1.2;
            margin: auto;
            margin-bottom: 30px;
        }
    }

    .line{
        width: 65px;
        height: 2px;
        background-color: #000;
        margin: auto;
        margin-bottom: 70px;
    }

    .services-listing{
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
        }

        .service-item{
            height: 450px;
            position: relative;
            display: flex;
            align-items: flex-end;
            overflow: hidden;
            margin-bottom: 40px;
            @media (min-width: 1200px){
                width: 32%;
            }
            &:hover{
                .photo{
                    transform: scale(1.2);
                }
            }
            .photo{
                transition: 0.4s;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .title{
                position: relative;
                z-index: 2;
                width: 100%;
                padding: 20px;
                text-align: center;
                color: #fff;
                background-color: rgba(7, 58, 95, 0.86);;
            }
        }
    }
}