.certification-section{
    margin-top: 50px;
    margin-bottom: 100px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            width: 800px;
            justify-content: space-between;
            align-items: center;
        }
        .icon-container{
            background-color: #e4e4e4;
            padding: 50px;
            text-align: center;
            margin-bottom: 50px;
            @media (min-width: 1200px){
                margin-right: 50px;
                margin-bottom: 0;
            }
            .text{
                font-size: 14px;
            }
        }
        .text-container{
            font-size: 16px;
            text-align: center;
        }
    }
}