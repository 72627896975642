.footer-content{
    background-color: #1F1F1F;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #fff;
    @media (min-width: 1200px){
        height: 500px;
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
            align-items: center;
           
        }
    }
    .column{
        margin-bottom: 60px;
        &:last-child{
            margin-bottom: 0;
        }
        &.contact{
            @media (min-width: 1200px){
                width: 25%;
            }
        }

        &.newsletter{
            height: 400px;
            @media (min-width: 1200px){
                width: 35%;
                height: 500px;
                margin-left: 30px;
                margin-bottom: 0;
                margin-top: -50px;
            }
        }
        .logo{
            filter: brightness(0) invert(1);
            margin-bottom: 30px;
        }
        .address{
            font-size: 14px;
            margin-bottom: 30px;
        }
        .contact-item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .icon{
                background-color: #50616F;
                color: #fff;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                border-radius: 50%;
            }
            .text{
                width: 150px;
                font-size: 12px;
                font-weight: 300;
                @media (min-width: 360px){
                    font-size: 12px;
                }
            }
        }
    }


    .footer-form{
        font-size: 14px;
        padding-top: 15px;
        @media (min-width: 1200px){
            width: 350px;
        }
        .title{
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 20px;
        }

        .input-item{
            margin-bottom: 30px;
            @media (min-width: 1200px){
                width: 100%;
            }
            input{
                background-color: transparent;
                color: #fff;
                border: 0;
                border-bottom: 1px solid #fff;
                width: 100%;
                outline: none;
            }

            textarea{
                background-color: transparent;
                color: #fff;
                border: 0;
                border-bottom: 1px solid #fff;
                width: 100%;
                height: 100px;
                resize: none;
                outline: none;
            }
        }


        .input-row{
            @media (min-width: 1200px){
                display: flex;
                justify-content: space-between;
            }
            .input-item{
                margin-bottom: 30px;
                @media (min-width: 1200px){
                    width: 47%;
                }
            }
        }

        .submit{
            background-color: #073A5F;
            color: #fff;
            text-align: center;
            display: block;
            padding: 10px;
            border-radius: 4px;
            transition: 0.4s;
            &:hover{
                opacity: 0.8;
            }
        }
    }

    .newsletter-container{
        width: 100%;
        position: relative;
        height: 100%;
        .image{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .content{
            width: 100%;
            height: 100%;
            position: absolute;
            padding: 20px;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .call-text{
                text-transform: uppercase;
                margin-bottom: 20px;
                font-size: 22px;
                text-align: center;
            }

            .footer-input{
                background-color: #fff;
                padding: 20px;
                border: 0;
                font-size: 14px;
                width: 100%;
                margin-bottom: 30px;
                height: 35px;
                text-align: center;
                outline: none;
            }
            .submit{
                background-color: #073A5F;
                color: #fff;
                text-align: center;
                display: block;
                padding: 10px;
                border-radius: 4px;
                width: 150px;
                font-size: 14px;
                transition: 0.4s;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }


    
}

.copyright-footer{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 1200px){
            justify-content: space-between;
            flex-direction: row;
        }

        .social-row{
            display: flex;
            margin-bottom: 10px;
            @media (min-width: 1200px){
                margin-bottom: 0;
            }
            .social-item{
                margin-left: 10px;
                margin-right: 10px;
                font-size: 26px;
                color: #fff;
                transition: 0.4s;
                &:hover{
                    opacity: 0.8;
                }
            }
        }

        .copyright-text{
            font-weight: 100;
            font-size: 12px;
            color: #fff;
        }
    }
}