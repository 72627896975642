.index-footer {
    background-color: #272525;
    padding: 1.2rem;
}

.index-footer__logo {
    display: block;
    margin: auto;
    width: 6rem;
}
