.mobile-search-input{
    position: fixed;
    top: var(--main-header-height);
    right: 0;
    width: 100%;
    background-color: #3E3E3E;
    padding: 20px;
    z-index: 10;
    transform: translateX(100%);
    transition: 0.4s;
    &.active{
        transform: translateX(0);
    }
    input{
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid #fff;
        color: #fff;
        width: 100%;
        padding-bottom: 5px;
        outline: none;
        font-size: 16px;
        &::placeholder{
            color: #fff;
        }
    }
}