.course-bottom-banner{
    margin-top: 80px;
    @media (min-width: 1200px){
        display: flex;
    }
    .photo{
        @media (min-width: 1200px){
            width: 50%;
        } 
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text-container{
        background-color: #062740;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: -15px;
        @media (min-width: 1200px){
            width: 50%;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
        } 
        .title{
            text-transform: uppercase;
            font-weight: 900;
            color: #fff;
            font-size: 32px;
        }
        .subtitle{
            font-size: 14px;
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 40px;
        }

        .ingress{
            background-color: #C20E1A;
            text-align: center;
            color: #fff;
            font-size: 14px;
            padding: 20px;
            transition: 0.4s;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}