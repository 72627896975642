.home-courses{
    width: 100%;
    height: 550px;
    position: relative;
    .bg-image{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding-top: 50px;
        padding-bottom: 50px;
        .container{
            position: relative;
            .title{
                text-transform: uppercase;
                font-size: 32px;
                color: #fff;
                margin-bottom: 10px;
                text-align: center;
                line-height: 1.2;
            }
            .subtitle{
                color: #fff;
                text-align: center;
                font-size: 14px;
                margin-bottom: 40px;
            }
        }
    }

    .desktop-slides-control{
        display: none;
        justify-content: space-between;
        color: #fff;
        font-size: 42px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 190px;
        @media (min-width: 1200px){
            display: flex;
            padding-left: 20px;
            padding-right: 20px;
        }
        .desktop-prev-slide, .desktop-next-slide{
            cursor: pointer;
        }
        
    }

    .home-courses-slider{
        margin-bottom: 30px;
        @media (min-width: 1200px){
            margin-left: 50px;
            margin-right: 50px;
        }
        .swiper-pagination{
            top: unset;
            bottom: 0 !important;
        }
        .swiper-pagination-progressbar{
            background: #fff;
        }
        .swiper-pagination-progressbar-fill{
            background: #ababab;
        }

        .course-item{
            width: 100%;
            height: 230px;
            position: relative;
            margin-bottom: 40PX;
            display: block;
            .flag{
                position: absolute;
                top: -10px;
                left: 20px;
                font-size: 42px;
                z-index: 3;
                color: #D3A400;
            }
            .photo{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.7;
                }
            }

            .content-container{
                width: 90%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .title{
                    font-weight: 100;
                    font-size: 24px;
                    width: 100%;
                    text-align: left;
                }
                .subtitle{
                    text-align: left;
                    font-weight: 900;
                    font-size: 20px;
                }
            }
        }
    }

    .all-courses{
        background-color: #C20E1A;
        color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 12px;
        width: 100%;
        display: block;
        text-align: center;
        transition: 0.4s;
        &:hover{
            opacity: 0.8;
        }
        @media (min-width: 1200px){
            width: 350px;
            margin: auto;
        }
    }
}


.home-courses .home-courses-slider .course-item .content-container .title {
    font-weight: 300;
}