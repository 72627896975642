.blog-listing{
    margin-top: 100px;
    @media (min-width: 1200px){
        margin-top: 150px;
    }
    .container{
        @media (min-width: 1200px){
            width: 800px;
            margin: auto;
        }
    }
    .blog-item{
        display: block;
        margin-bottom: 70px;
        &:hover{
            .title{
                text-decoration: underline;
            }
            img{
                transform: scale(1.2);
            }
        }
        .photo{
            width: 100%;
            height: 250px;
            margin-bottom: 20px;
            overflow: hidden;
            img{
                transition: 0.4s;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .category{
            color: rgba(7, 58, 95, 0.96);
            font-size: 12px;
            @media (min-width: 1200px){
                font-size: 14px;
            }
        }

        .title{
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 1.2;
        }
    }
}