.cases-listing{
    margin-top: 80px;
    .container{
        @media (min-width: 1200px){
            width: 800px;
            margin: auto;
        }

        .case-item{
            width: 100%;
            margin-bottom: 50px;
            @media (min-width: 1200px){
                display: flex;
                align-items: center;
            }
            .photo{
                width: 100%;
                height: 200px;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #bbb;
                @media (min-width: 1200px){
                    width: 250px;
                    border-bottom: 0;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-right: 1px solid #bbb;
                    padding-right: 60px;
                    margin-right: 60px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text-container{
                @media (min-width: 1200px){
                    flex: 1;
                }
                .title{
                    font-weight: 900;
                    text-transform: uppercase;
                    color: rgba(7, 58, 95, 0.96);
                    margin-bottom: 10px;
                }

                .content{
                    font-size: 14px;
                }
            }
        }
    }
}