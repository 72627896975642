.gray-banner{
    background-color: #e4e4e4;
    padding-top: 50px;
    padding-bottom: 50px;
    .title{
        font-size: 34px;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 20px;
        text-align: center;
        @media (min-width: 1200px){
            width: 800px;
            margin: auto;
            margin-bottom: 20px;
        }
    }

    .subtitle{
        font-size: 20px;
        text-align: center;
        @media (min-width: 1200px){
            width: 800px;
            margin: auto;
            margin-bottom: 20px;
        }
    }
}