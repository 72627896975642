.blog-detail-content{
    .category{
        color: rgba(7, 58, 95, 0.96);
        font-size: 12px;
        @media (min-width: 1200px){
            font-size: 14px;
        }
    }

    .title{
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.2;
    }
    .content-text{
        font-size: 16px;
    }

    .content-media{
        margin-top: 30px;
        margin-bottom: 50px;
        @media (min-width: 1200px){
           display: flex;
        }
        .media-item{
            margin-bottom: 20px;
            width: 100%;
            @media (min-width: 1200px){
                width: 300px;
                margin-right: 20px;
            }
            img{
                width: 100%;
            }
        }
    }
}