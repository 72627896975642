.director-section{
    margin-bottom: 80px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            padding-left: 185px;
        }
        .left-side{
            margin-bottom: 50px;
            @media (min-width: 1200px){
                width: 200px;
                margin-right: 100px;
            }
            .photo{
                width: 150px;
                height: 150px;
                border-radius: 20px;
                overflow: hidden;
                margin-bottom: 20px;
                @media (min-width: 1200px){
                    width: 100%;
                    height: 200px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cocver;
                }
            }

            .person-history{
                display: flex;
                .icon{
                    width: 60px;
                    margin-right: 10px;
                    @media (min-width: 1200px){
                        width: 40px;
                    }
                }
                .text{
                    flex: 1;
                    font-size: 12px;
                    color: #979797;
                }
            }
        }

        .right-side{
            @media (min-width: 1200px){
                flex: 1;
                padding-top: 30px;
            }
            .title{
                text-transform: uppercase;
                font-weight: 900;
                font-size: 20px;
                line-height: 1.2;
            }
            .job{
                font-size: 14px;
                margin-bottom: 20px;
            }
            .text-container{
                font-size: 14px;
                @media (min-width: 1200px){
                    width: 500px;
                }
            }
        }
    }
}