.home-statements{
    padding-top: 70px;
    padding-bottom: 70px;
    .slide-control{
        display: flex;
        justify-content: center;
        font-size: 26px;
        margin: 20px;
        .statement-prev{
            margin-right: 10px;
            cursor: pointer;
            color: #979797;
        }
        .statement-next{
            cursor: pointer;
            color: #979797;
        }
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            flex-direction: row-reverse;
        }

        .right{
            @media (min-width: 1200px){
                width: 50%;
                padding-left: 5%;
            }
            .video-container{
                width: 100%;
                height: 250px;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .left{
            margin-top: 40px;
            @media (min-width: 1200px){
                width: 50%;
                margin-top: 0;
            }

            .small-title{
                font-size: 12px;
                margin-bottom: 15px;
                text-align: center;
                line-height: 1;
                font-weight: 900;
            }
            .big-title{
                text-transform: uppercase;
                font-size: 24px;
                text-align: center;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 30px;
            }

            .statement-text{
                font-size: 14px;
                text-align: center;
                font-style: italic;
                margin-bottom: 10px;
            }

            .person-item{
                display: flex;
                justify-content: center;
                align-items: center;
                .photo{
                    // width: 60px;
                    // height: 60px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 10px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        width: 105px;
                        height: 105px;
                        border-radius: 100%;
                    }
                }
                .text{
                    .name{
                        text-transform: uppercase;
                        font-size: 16px;
                        color: rgba(7, 58, 95, 0.96);
                        font-weight: 500;
                        font-family: Acrom;

                        @include media(get-bp(desktop)) {
                            font-size: 21px;
                        }
                    }
                    .function{
                        font-size: 12px;
                        font-weight: 500;
                        text-transform: uppercase;
                        font-family: Acrom;
                        @include media(get-bp(desktop)) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}