.trainning-bottom-slider{
    padding-top: 50px;
    padding-bottom: 50px;
    @media (min-width: 1200px){
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
        }
    }

    .desktop-photo{
        display: none;
        @media (min-width: 1200px){
            display: block;
            width: 350px;
            margin-top: -50px;
            margin-left: -30px;
        }
    }
    .gray-box{
        padding: 20px;
        background-color: #e4e4e4;
        margin-bottom: 20px;
        position: relative;
        border-radius: 0px 0px 50px ;
        @media (min-width: 1200px){
            width: 370px;
        }

        .statement-item{
            .rate{
                margin-bottom: 15px;
                color: #0E87CB;
            }
            .statement-text{
                font-size: 16px;
                font-style: italic;
                font-weight: 300;
                color: #000;
                margin-bottom: 30px;
            }

            .statement-author{
                text-transform: uppercase;
                font-weight: 900;
                font-size: 20px;
                color: rgba(7, 58, 95, 0.96);
            }
            
            .job{
                font-size: 14px;
            }
        }

        .slider-controls-container{
            display: flex;
            justify-content: center;
            margin-top: 30px;
            .trainning-prev, .trainning-next{
                margin-left: 10px;
                margin-right: 10px;
                font-size: 32px;
                color: #979797;
                cursor: pointer;
            }
        }
    }
}


.button-container{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 1200px){
        flex-direction: row;
        justify-content: center;

        .button-whatsapp {
            margin-right: 30px;
        }
    }

    p{
        text-align: center;
    }
    .button-whatsapp{
        margin-top: 10px;
        background-color: #158A02;
        padding: 20px;
        color: #fff;
        border-radius: 8px;
        font-size: 14px;
        transition: 0.4s;
        display: flex;
        align-items: center;
        font-size: 13px;
        padding: 11px 22px;

        i {
            font-size: 29px;
            margin-right: 12px;
        }
        &:hover{
            opacity: 0.8;
        }
    }
}