.home-results{
    background-color: #F6F8FA;
    padding-top: 40px;
    padding-bottom: 80px;
    .results-title{
        text-align: center;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 32px;
    }
    .results-subtitle{
        text-align: center;
        margin-bottom: 70px;
    }

    .results-listing{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
        }
        .result-item{
            text-align: center;
            margin-bottom: 40px;
            @media (min-width: 1200px){
                margin-bottom: 0;
                margin-left: 30px;
                margin-right: 30px;

            }
            .icone{
                width: 80px;
                height: 80px;
                margin: auto;
                margin-bottom: 15px;
            }
            .number{
                font-weight: 900;
                font-size: 24px;
            }
        }
    }
}