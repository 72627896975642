.shield-section{
    background-color: #e4e4e4;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    .icone{
        margin-bottom: 10px;

        img {
            max-width: 130px;
        }
    }
    .title{
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 20px;
        @media (min-width: 1200px){
            width: 450px;
            margin: auto;
            margin-bottom: 20px;
        }
    }

    .subtitle{
        font-size: 14px;
        font-style: italic;
        position: relative;
        @media (min-width: 1200px){
            width: 450px;
            margin: auto;
        }
        &::after{
            content: '';
            width: 60px;
            height: 2px;
            background-color: #073A5F;
            position: absolute;
            bottom: -20px;
            left: calc(50% - 30px);
        }
    }
}