.filter-row{
    margin-top: 50px;
    margin-bottom: 50px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .left-side{
            .order-container{
                margin-bottom: 40px;
                @media (min-width: 1200px){
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                }
                .title{
                    font-size: 18px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    @media (min-width: 1200px){
                        margin-bottom: 0;
                    }
                }
                .order-select{
                    width: 100%;
                    height: 45px;
                    outline: none;
                    border: 0;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    @media (min-width: 1200px){
                        width: 250px;
                    }
                }
            }
        }


        .right-side{
            .search-input{
                display: flex;
                border-bottom: 1px solid #ccc;
                font-size: 18px;
                @media (min-width: 1200px){
                    width: 350px;
                }
                .icon{
                    margin-right: 10px;
                    cursor: pointer;
                }
                input{
                    border: 0;
                    outline: none;
                    flex: 1;
                    font-size: 14px;
                    @media (min-width: 1200px){
                        font-size: 16px;
                    }
                }
            }
        }
    }
}