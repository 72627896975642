// Importe webfonts aqui.
//
// @example
//     @include import-font-face('Nome da fonte', '/caminho/da/fonte', $weight?, $style?);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto');
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-italic', normal, italic);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold', bold);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold-italic', bold, italic);


@include import-font-face('Acrom', '../fonts/Acrom/Acrom-Bold', bold);
@include import-font-face('Acrom', '../fonts/Acrom/Acrom-ExtraBold', 900);
@include import-font-face('Acrom', '../fonts/Acrom/Acrom-Medium', 500);
@include import-font-face('Acrom', '../fonts/Acrom/Acrom-Regular', 700);
@include import-font-face('Acrom', '../fonts/Acrom/Acrom-Light', 300);
@include import-font-face('Acrom', '../fonts/Acrom/Acrom-Thin', 100);