.belt-mentoring{
    margin-top: 80px;
    margin-bottom: 80px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
        }
        .left{
            margin-bottom: 50px;
            @media (min-width: 1200px){
                width: 40%;
                margin-bottom: 0;
            }
            .title{
                font-weight: 900;
                text-transform: uppercase;
                font-size: 34px;
                line-height: 1.2;
                margin-bottom: 30px;
            }
            p{
                font-size: 16px;
                b{
                    font-weight: 900;
                }
            }
        }

        .right{
            @media (min-width: 1200px){
                width: 30%;
                padding-left: 5%;
            } 
            img{
                width: 100%;
            }
        }
    }

    a{
        background-color: #073A5F;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        padding:  20px;
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 50px;
        transition: 0.4s;
        @media (min-width: 1200px){
            width: 250px;
        }
        &:hover{
            opacity: 0.8;
        }
    }
}