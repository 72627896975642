.vision-container{
    margin-bottom: 80px;
    .container{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
        }
        .vision-item{
            margin-bottom: 50px;
            text-align: center;
            @media (min-width: 1200px){
                margin-left: 100px;
                margin-right: 100px;
                width: 300px;
            }
            .icon{
                width: 50px;
                height: 50px;
                margin: auto;
                margin-bottom: 10px;
            }
            .title{
                text-transform: uppercase;
                font-weight: 900;
                margin-bottom: 10px;
                font-size: 24px;
            }

            .line{
                width: 60px;
                height: 3px;
                background-color: #073A5F;
                margin: auto;
                margin-bottom: 20px;
            }

            .text{
                font-size: 14px;
            }

        }
    }
}