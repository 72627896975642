.team-direction{
    margin-top: 50px;
    margin-bottom: 50px;
    .container{
        .title{
            text-transform: uppercase;
            font-size: 36px;
            font-weight: 900;
            text-align: center;
            margin-bottom: 10px;
        }
        .subtitle{
            text-align: center;
            margin-bottom: 30px;
            @media (min-width: 1200px){
                width: 500px;
                font-size: 14px;
                margin: auto;
                margin-bottom: 60px;
            }
        }

        .tags-listing{
            @media (min-width: 1200px){
                display: flex;
                justify-content: center;
            }
            .tag-item{
                padding: 20px;
                background-color: #e4e4e4;
                border-radius: 8px;
                text-align: center;
                margin-bottom: 40px;
                .icon{
                    width: 80px;
                    height: 80px;
                    margin: auto;
                    margin-bottom: 20px;
                }
                .text{
                    font-size: 14px;
                }
                @media (min-width: 1200px){
                    width: 200px;
                }
                &.center{
                    @media (min-width: 1200px){
                        width: 350px;
                        margin-left: 40px;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}