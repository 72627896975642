.home-partners{
    background-color: #F6F8FA;
    padding-top: 50px;
    padding-bottom: 50px;
    .partner-item{
        width: 100%;
        height: 150px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}