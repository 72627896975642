.sliced-main-banner{
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    @media (min-width: 1200px){
        height: 650px;
    }
    &::before{
        content: '';
        position: absolute;
        bottom: -60px;
        left: -90px;
        width: 280px;
        height: 120px;
        background-color: #fff;
        z-index: 2;
        transform: rotate(20deg);
        @media (min-width: 700px){
            width: 100%;
            height: 280px;
            bottom: -220px;
            left: -300px;
            transform: rotate(8deg);
        }
    }

    &::after{
        content: '';
        position: absolute;
        bottom: -60px;
        right: -90px;
        width: 280px;
        height: 120px;
        background-color: #fff;
        z-index: 2;
        transform: rotate(-20deg);
        @media (min-width: 700px){
            width: 100%;
            height: 280px;
            bottom: -220px;
            right: -300px;
            transform: rotate(-8deg);
        }
    }
    .photo{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        filter: brightness(0.7);
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content-container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        .text{
            text-transform: uppercase;
            color: #fff;
            font-size: 42px;
            font-weight: 900;
            line-height: 1.2;
            @media (min-width: 1200px){
                font-size: 72px;
                width: 650px;
            }
        }

        p{
            font-style: italic;
            color: #fff;
            font-size: 20px;
        }
    }
}