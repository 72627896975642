.course-detail-people-section{
    margin-top: 50px;
    margin-bottom: 50px;
    @media (min-width: 1200px){
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .container{
        @media (min-width: 1200px){
            display: flex;
            justify-content: space-between;
        }

        .left{
            @media (min-width: 1200px){
                // width: 50%;
            }

            .text-box{
                margin-bottom: 40px;
                .title{
                    text-transform: uppercase;
                    font-weight: 900;
                    font-size: 22px;
                    margin-bottom: 15px;
                }
                .text-content{
                    font-size: 14px;
                    @media (min-width: 1200px){
                        font-size: 18px;
                    }
                }
            }
        }

        .right{
            @media (min-width: 1200px){
                width: 25%;
            }

            .title{
                text-transform: uppercase;
                font-weight: 900;
                font-size: 22px;
                margin-bottom: 15px;
            }

            .photo{
                width: 100%;
                border-radius: 20px;
                overflow: hidden;
                height: 300px;
                margin-bottom: 30px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name-row{
                display: flex;
                margin-bottom: 20px;
                .icon{
                    margin-right: 15px;
                    width: 45px;
                }

                .text{
                    flex: 1;
                    .name{
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: 900;
                    }
                    .job{
                        font-size: 14px;
                    }
                }
            }

            .description{
                font-size: 14px;
                font-style: italic;
                color: #ababab;
            }
        }
    }
}